.contact-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    animation: fadeIn 2s;
    align-items: center;
}

.contact-content-container{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    max-width: 300px;
}

.contact-top-section-container{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.contact-top-section-center-container{
    display: flex;
    margin-bottom: 40px;
    margin-top: -50px;
}

.contact-top-section-center-container p{
    margin: 0 10px 0 10px;
    font-size: 20px;
}

.contact-bottom-section-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-top-sentence-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.contact-top-sentence-container p{
    font-size: 25px;
    text-align: center;
}

.contact-icons-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-icon-image-container{
    width: 4%;
    margin: 0 15px 0 15px;
    min-width: 35px;
}

.contact-icon-image-container .email-target{
    filter: invert(100%);
}

.contact-icons-container img{
    width: 100%;
}

.contact-bottom-sentence-container{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.contact-bottom-sentence-container p{
    margin: 0 10px 0 10px;
    font-size: 20px;
    text-align: center;
}