.coffee-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    animation: fadeIn 2s;
}

.coffee-about-top-navigation-container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". logo menu";
    align-items: center;
    
}

.coffee-about-logo-container{
    width: 60%;
    grid-area: logo;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
}

.coffee-about-logo-container img{
    width: 100%;
    cursor: pointer;
    min-width: 200px;
}

.coffee-menu-bar-container{
    grid-area: menu;
    justify-self: center;
}

.coffee-content-container{
    width: 100%;
    display: flex;
    flex: 1;
}

.coffee-big-image-container{
    width: 40%;
    display: flex;
    align-items: center;
    min-width: 350px;
}

.coffee-big-image-container img{
    filter: invert(100%);
    width: 100%;
}

.coffee-text-container{
    width: 60%;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    margin-bottom: 50px;

}

.coffee-text-title-container{
    font-size: 10vw;
}

.coffee-text-title-container h2{
    margin-block-end: 0;
    margin-block-start: 0;
    letter-spacing: -4.05px;;
    font-weight: 500;
}

.coffee-text-paragraph-container{
    font-size: 22px;
    max-width: 650px;
    margin-left: 10px;
}

.coffee-text-paragraph-container p{
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: .48px;
    margin-right: 30px;
}

@media screen and (width < 930px) {
    .coffee-content-container{
        flex-direction: column;
        align-items: center;
    }
    .coffee-text-container{
        align-items: center;
        min-width: 375px;
        padding-left: 0;
    }

    .coffee-text-paragraph-container{
        text-align: center;
        margin-left: 0;
    }

    .coffee-text-paragraph-container p{
        margin-right: 0;
    }
}

@media screen and (width < 414px){
    .coffee-text-paragraph-container{
        max-width: 300px;
    }
}