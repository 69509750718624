.menu-routes-container-open{
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    transition-duration: .5s;
    background-color: #060424;
}

.menu-routes-container-closed{
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 0;
    transition-duration: .5s;
    background-color: #060424;
}

.menu-routes-logo-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-routes-logo-open{
    width: 20%;
    min-width: 250px;
    animation: fadeIn 3s;
}
.menu-routes-logo-closed{
    display: none;
    animation: fadeOut 2s;
}



.menu-routes-container{
    display: flex;
    justify-content: center;
}

.menu-routes-list-container-open{
    display: flex;
    flex-direction: column;
    width: fit-content;
    color: #E59368;
    align-items: center;
}

.menu-routes-list-container-open p{
    font-size: 2em;
    animation: fadeIn 2s;
    cursor: pointer;
    letter-spacing: 0.48px;
}

.menu-routes-list-container-open a{
    transition-duration: .3s;
}

.menu-routes-list-container-open a:link{
    color: #E59368;
}

.menu-routes-list-container-open a:active{
    color: #E59368;
}

.menu-routes-list-container-open a:visited{
    color: #E59368;
}

.menu-routes-list-container-open a:hover{
    color: white;
    transition-duration: .3s;
}


.menu-routes-list-container-closed p{
    font-size: 0em;
    animation: fadeOut 2s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@keyframes fadeOut {
    0% { opacity: 1;}
    100% {opacity: 0;}
}