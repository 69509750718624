.home-container{
    height: 100vh;
    background:  linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url('../image/bg.png');
    background-size:cover;
    display: flex;
    flex-direction: column;
    animation: fadeIn 2s;
}

.top-bar-container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
}

.top-bar-instagram-container{
    width: 10%;
    min-width: 40px;
    max-width: 50px;
}
.top-bar-instagram-logo{
    width: 100%;
    cursor: pointer;
}

.top-bar-logo-container{
    width: 70%;
}

.top-bar-logo{
    width: 100%;
    min-width: 150px;
} 

.oasis-big-text-container{
    font-size: max(10vw, 40px);
    width: 100%;
    display: flex;
    justify-content: center;
    color: #E59368;
    flex: 1;
    align-items: center;
}

.oasis-direction-text-container{
    width: 100%;
    display: flex;
    justify-content: center;
    color: #E59368;
    align-items: flex-start;
    font-size: calc(.5vw + 15px);
}

.oasis-direction-text-container p{
    margin-block-start: 0;
}